// unique scripts go here.
const sendFormEnter = e => {
    if (window.event.keyCode == '13') {
        //sendFormToLeadsStore();
    }
}
const sendFormToLeadsStore = e => {
    //get form data from form.contact-form
    const data = getFormData('.contact-form');

    fetch('https://phpstack-427964-2786102.cloudwaysapps.com/api/form-data', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }

    }).then(response => {
        console.log(response);
    });

}

const sendFormToApi = e => {
    e.preventDefault();

    const data = getFormData('.contact-form');
    const tipoDocumento = $('#cpf-cnpj').attr('name');
    const idGA_lead = $('#idGA_lead').val();

    data.state_id = document.querySelector('select[name="UF"] option:checked').dataset.stateid;
    data.url = window.location.href;

    showOverlay();

    fetch('includes/put_lead.php', {
        method: "PUT",
        headers: {
            'Content-type': 'application/json'
        },
        body: JSON.stringify({
            data
        })
    })
        .then(response => {

            if (response.ok) {

                Sweetalert2.fire({
                    title: 'Sucesso!',
                    text: 'Proposta enviada com sucesso!',
                    icon: 'success'
                })

                if (location.pathname == '/') {
                    console.log('raiz do site')
                    window.dataLayer.push({
                        'event': 'send',
                        'tipoDocumento': tipoDocumento,
                        'idGA_lead': idGA_lead
                    });
                } else {
                    console.log('não é raiz do site')
                    window.dataLayer.push({
                        'event': 'send',
                        'idGA_lead': idGA_lead
                    });
                }


                clearForm();

            } else {
                Sweetalert2.fire({
                    title: 'Ops!',
                    text: 'Houve um erro no envio da sua proposta, tente novamente mais tarde.',
                    icon: 'error'
                })
            }

            hideOverlay();
        });


}

const setSessionAPIToken = () => {
    $.ajax({
        url: 'includes/get_info.php',
        type: 'GET',
    })
}

const getPlans = () => {
    $.ajax({
        url: 'includes/get_plans.php',
        type: 'GET',
    });
}

const getStates = e => {
    showOverlay();

    $.ajax({
        type: "GET",
        dataType: "json",
        url: "includes/estados.json",
        success: function (response) {

            //if (response.message === 'Success') {

            const stateSelect = document.querySelector('select[name="UF"]');
            let html = '<option disabled selected>Selecione seu Estado</option>';

            response.forEach(state => {
                html += `<option data-stateid="${state.uf}" value="${state.uf}">${state.estado}</option>`;
            });

            stateSelect.innerHTML = html;
            /*  } else {
                 Sweetalert2.fire({
                     title: 'Ops!',
                     text: 'Houve um erro com nossa APi, tente novamente mais tarde.',
                     icon: 'error'
                 })
             } */

            hideOverlay();
        }
    });
}

const getCities = e => {

    showOverlay();

    $.ajax({
        type: "GET",
        dataType: "json",
        url: "includes/cidades.json",
        data: {
            uf: e.target.value
        },
        success: function (response) {

            //if (response.message === 'Success') {

            const citySelectWrapper = document.querySelector('label.hidden');
            const citySelect = document.querySelector('select[name="cidade"]');
            let html = '';


            let ufForm = e.target.value;


            response.forEach(city => {
                if (ufForm === `${city.uf}`) {
                    html += `<option value="${city.município}">${city.município}</option>`;
                }
            });

            citySelect.innerHTML = html;

            fadeIn(citySelectWrapper);
            //}

            /* else {
                Sweetalert2.fire({
                    title: 'Ops!',
                    text: 'Houve um erro com nossa APi, tente novamente mais tarde.',
                    icon: 'error'
                })
            }
 */
            hideOverlay();
        }
    });
}


const maskInstancing = () => {
    var SPMaskBehavior = function (val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
        spOptions = {
            onKeyPress: function (val, e, field, options) {
                field.mask(SPMaskBehavior.apply({}, arguments), options);
            }
        };

    $('input[name="telefone"]').mask(SPMaskBehavior, spOptions);
    $('input[name="CPF"]').mask('000.000.000-00', {
        reverse: false
    });
    $('input[name="data_de_nascimento"]').mask('00/00/0000');

}

const changeCPFCNPJInput = e => {

    if (e.target.checked) {
        $("#cpf-cnpj").attr("placeholder", "CNPJ");
        $("#cpf-cnpj").attr("name", "CNPJ");

        $("#cpf-cnpj").mask("00.000.000/0000-00", {
            reverse: false,
        });
    } else {
        $("#cpf-cnpj").attr("placeholder", "CPF");
        $("#cpf-cnpj").attr("name", "CPF");
        $("#cpf-cnpj").mask("000.000.000-00", {
            reverse: false,
            clearIfNotMatch: true,
        });
    }

    $("#cpf-cnpj").val('');

}

const getUTMzz = () => {
    console.log('utmzz');
    if (getCookie("__utmzz")) {
        console.log('utmz ok: ', getCookie("__utmzz"));
        var utms = getCookie("__utmzz");
        var utmsA = utms.split("|");
        var utmsFinal = [];
        utmsA.forEach(function (el) {
            var splitedEl = el.split("=");
            utmsFinal[splitedEl[0]] = splitedEl[1];
        });

        document.querySelector('input[name="campaign"]').value = utmsFinal["utmccn"];
        document.querySelector('input[name="medium"]').value = utmsFinal["utmcmd"];
        document.querySelector('input[name="origin"]').value = utmsFinal["utmcsr"];
    } else {
        getUTMzz();
    }
}

const create_UUID = () => {
    var dt = new Date().getTime();
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
}

const slickInstancing = () => {
    const isAmilplano = window.location.href.indexOf("/amilplano") > -1;

    $('.slick-wrapper').slick({
        dots: true,
        arrows: false,
        autoplay: isAmilplano,
    });

    $('ul[role="tablist"]').find('li').each(function (i) {
        $(this).attr({
            'tabindex': '0'
        });
    });
}
