
function init() {

    hideOverlay();

    // const header = new Header();

    document.querySelectorAll('a[href*="#"]:not(a[href="#"])').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();

            // if (helpers.isMobile) {
            //     if (!anchor.classList.contains('btn')) {
            //         header.toggleMenu();

            //         document.querySelectorAll('body, html').forEach(item => {
            //             item.classList.remove('no-scroll');
            //         });
            //     }
            // }

            let href = this.getAttribute('href').substring(this.getAttribute('href').indexOf('#'), this.getAttribute('href').indexOf('?'));
            let scrollTarget = this.getAttribute('href').indexOf('?') !== -1 ? href : this.getAttribute('href');

            document.querySelector(scrollTarget).scrollIntoView({
                behavior: 'smooth'
            });
        });
    });

    let contactForm = document.querySelector('.contact-form'); 

    if ( contactForm ) {
        const buttonSubmit = document.querySelector('.submit-form');
        buttonSubmit.addEventListener('click', sendFormToLeadsStore);
        contactForm.addEventListener('submit', sendFormToApi);
    }

    getStates();

    let stateSelect = document.querySelector('select[name="UF"]');

    if ( stateSelect ) {
        stateSelect.addEventListener('change', getCities);
    }

    let hasCNPJInput = document.querySelector('input[name="tem_CNPJ"]'); 

    if ( hasCNPJInput ) {
        hasCNPJInput.addEventListener('change', changeCPFCNPJInput);
    }

    // js masks
    maskInstancing();

    // session token for api
    //setSessionAPIToken();
    // ?

    //getPlans();
    
    // utmzz cookie
   
    // carousel 
    slickInstancing();
    
}

// window load binds 
window.onload = init;
function getUUID() {
    let uuid = localStorage.getItem('uuid');
    if (!uuid) {
        uuid = create_UUID();
        localStorage.setItem('uuid', uuid);
    }
    return uuid;
}
function DOMLoaded() {
    $('#idGA_lead').val(getUUID());
    // these are not always necessary but sometimes they fuck with ya
    if (helpers.iOS) {
        document.querySelector('html').classList.add('ios');
    } else if (helpers.IE()) {
        document.querySelector('html').classList.add('ie');
    }
    setTimeout(() => {
        
        getUTMzz();    
    }, 1000);
    

}

// domcontent binds 
document.addEventListener('DOMContentLoaded', DOMLoaded);
